import React, { useEffect, useState } from "react";
import "../../assets/styles/trajets.scss";
import TopBar from "../../components/topBar";
import BlocTrajet from "../../components/blocTrajet";
import Services from "../../services/global.service";
import Header from "../../components/header";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Loader from "../../components/loader";

const MesTrajets = () => {
  const [activeTab, setActiveTab] = useState("tous"); // Track active tab
  const [allTrips, setAllTrips] = useState([]);
  const [filtredData, setFiltredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // ✅ Add loading state

  const getMyTargets = () => {
    setIsLoading(true); // ✅ Start loading
    Services.getAll("filteredtrajets")
      .then((res) => {
        // type trajet 1 status reservation should be not refused
        const filteredData = res?.data ? res.data.filter(trip => {
          // 0 => solidaire 
          if (trip.trajet.typeTrajet === 0) {
            return trip.trajet.trajet_reservation?.[0]?.status !== 'refused';
          }
          return true;
        }) : [];

        //  console.log(filteredData)
        //  console.log(res?.data)
        
     // Trier avec les trajets passés en dernier
     const now = new Date();
     const sortedData = filteredData.sort((a, b) => {
       const aDate = new Date(a.trajet.dateDepart);
       const bDate = new Date(b.trajet.dateDepart);
       
       if (aDate < now && bDate < now) {
         return bDate - aDate; // Pour les passés: du plus récent au plus ancien
       } else if (aDate < now) {
         return 1;
       } else if (bDate < now) {
         return -1;
       } else {
         return aDate - bDate; // Pour les futurs: du plus proche au plus lointain
       }
     });
        setAllTrips(sortedData);
       // setAllTrips(res?.data || []);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false); // ✅ Stop loading
      });
  };

  const filterData = (status) => {
    if (!allTrips) return;

    let filtered;
    if (status === "onHold") {
      filtered = allTrips.filter((trip) => trip.status == 1);
    } else if (status === "avenir") {
      filtered = allTrips.filter((trip) => trip.status == 2);
    } else if (status === "passes") {
      filtered = allTrips.filter((trip) => trip.status == 0);
    } else {
      filtered = allTrips; // Show all trips for "tous"
    }
    
    setFiltredData(filtered);
  };

  useEffect(() => {
    getMyTargets();
    // console.log({allTrips})
  }, []);

  useEffect(() => {
    filterData(activeTab); // Re-run filter whenever activeTab or allTrips changes
  }, [activeTab, allTrips]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Page mes trajets</title>
          <meta
            name="description"
            content="Page contenant tous mes trajets avec un filtre"
          />
        </Helmet>
      </HelmetProvider>

      <Header connected={true} />
      <div className="bg-grey mes-trajets-container">
        <div className="bg-white mt-8">
          <div className="px-16">
            <h1 className="bleuM500">Mes trajets</h1>
            <div className="mes-trajets-tabs">
              <ul>
                <li
                  className={activeTab === "tous" ? "active" : ""}
                  onClick={() => setActiveTab("tous")}
                >
                  Tous
                </li>
                <li
                  className={activeTab === "onHold" ? "active" : ""}
                  onClick={() => setActiveTab("onHold")}
                >
                  En attente
                </li>
                <li
                  className={activeTab === "avenir" ? "active" : ""}
                  onClick={() => setActiveTab("avenir")}
                >
                  À venir
                </li>
                <li
                  className={activeTab === "passes" ? "active" : ""}
                  onClick={() => setActiveTab("passes")}
                >
                  Passés
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="px-16 col-lg-12 mx-auto">
          <div className="trip-cards row">
            {isLoading ? ( // ✅ Show loader while fetching data
           <div className="container">
            <Loader />
           </div> 
              // <p className="legend mt-16 text-center">Chargement des trajets...</p>
            ) : filtredData.length !== 0 ? (
              filtredData.map((trajet,key) => {
                const uniqueKey = `${trajet.id}-${key}`;
              return( 
                <div key={uniqueKey} className="col-md-6 col-lg-4 col-xs-12">
                 <BlocTrajet
                  id={uniqueKey}
                  el={trajet}
                  data={trajet?.trajet}
                  type={trajet?.trajet?.type}
                  path="/mes-trajets/"
                  from={"myTargets"}
                  status={activeTab}
                  connected={true}
                />  
                </div> 
              )
             
})
            ) : (
              <p className="legend mt-16 text-center">Aucun trajet disponible.</p>
            )}
          </div>
        </div>

        <div className="h-xs-100 mb-5"></div>
        <TopBar />
      </div>
    </>
  );
};

export default MesTrajets;
