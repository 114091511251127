import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import IconClose from "../assets/images/iconClose.svg";

function ModalRetraite({ open, setOpen, selectedRetraite, setSelectedRetraite,autreCaisse,setAutreCaisse }) {
  const retraiteOptions = [
    "Malakoff Humanis",
    "AG2R La Mondiale",
    "Harmonie Mutuelle",
    "Klesia",
    "Apicil",
    "Pro BTP",
    "Autre",
    "Ne sais pas ou ne souhaite pas répondre",
  ];

  const [isOtherSelected, setIsOtherSelected] = useState(selectedRetraite === "Autre");
 

  useEffect(() => {
    setIsOtherSelected(selectedRetraite === "Autre");
    
  }, [selectedRetraite]);

  const handleRaisonChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "Autre") {
      setIsOtherSelected(true);
      setAutreCaisse(""); // Clear input for "Autre"
    } else {
      setIsOtherSelected(false);
      setAutreCaisse(selectedValue); // Store selected value
   
    }
   setSelectedRetraite(selectedValue); 
    // Update selectedRetraite state
  };

  const handleConfirm = () => {
    const finalSelection = isOtherSelected ? autreCaisse : selectedRetraite;
    //console.log("Confirmed selection:", finalSelection);
    //setSelectedRetraite(finalSelection);
    setOpen(false);
  };

  if (!open) return null;

  return (
    <Modal show={open} onHide={() => setOpen(false)}>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-start">
        <h2 className="beluM500">Cocher votre caisse de retraite</h2>
        <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <form>
          {retraiteOptions.map((option, index) => (
            <div key={index}>
              <label className="d-flex align-items-center">
                <input
                  type="radio"
                  name="retraite"
                  value={option}
                  checked={selectedRetraite === option}
                  onChange={handleRaisonChange}
                />
                <span className="custom-radio"></span> {option}
              </label>

              {/* Show input field when "Autre" is selected */}
              {option === "Autre" && isOtherSelected && (
                <div className="floating-input-container mb-3">
                  <input
                    type="text"
                    name="retraite-autre"
                    className="floating-input p-16"
                    placeholder=""
                    value={autreCaisse}
                    onChange={(e) => setAutreCaisse(e.target.value)}
                  />
                  <label className="floating-label">Veuillez spécifier</label>
                </div>
              )}
            </div>
          ))}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-primary-s w-100"
          onClick={handleConfirm}
          disabled={!selectedRetraite || (isOtherSelected && !autreCaisse.trim())}
        >
          Je confirme
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalRetraite;