import Avatar from "../assets/images/conducteur.svg";
import CheckFill from "../assets/images/checkfill.svg";
import Users from "../assets/images/multiple-man-woman.svg";
import Message from "../assets/images/Message.svg";
import Services from "../services/global.service";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../utils/utils";
import { useState } from "react";
const Conducteur = (props) => {
  
  const [loading,setLoading]=useState(false)
  const createdAt = new Date(props?.conducteur?.created_at);
  const memberSince = new Date(createdAt); // Replace with actual creation date
  const now = new Date();
  // Calculate the difference in years and months
  const yearsSinceCreation = now.getFullYear() - memberSince.getFullYear();
  let monthsSinceCreation =
    now.getMonth() - memberSince.getMonth() + yearsSinceCreation * 12;

  // Adjust if the day of the month in `now` is earlier than `memberSince`
  if (now.getDate() < memberSince.getDate()) {
    monthsSinceCreation -= 1;
  }

  monthsSinceCreation = monthsSinceCreation % 12;
  //  console.log('res',props?.reservation)
  const navigate = useNavigate();
  const checkdiscussion = (id) => {

    Services.getOne(id, "messages/getOrCreateMyDiscussions")
      .then((res) => {

        navigate("/discussion/" + res?.data?.id);
      })
      .catch((err) => {

      });
  };
  const handleUpdateStatus = async (status) => {
    // console.log(status)
    setLoading(true); // Active le loader
    // console.log(loading)
    try {
      await props?.updateStatus(status, props?.reservation?.id);
    } catch (error) {
      console.error("Erreur lors de l'acceptation :", error);
    } 
  };
  return (
    <>
      {props?.title && <h2 className="bleuM500 mt-24">Le conducteur</h2>}
      <div className="card-atchoum bg-white text-start mr-0">
        <div className="d-flex justify-content-between ">
          <div className="driver-info">
            {props?.conducteur?.photo_profil ? (
              <img
                src={props?.conducteur?.photo_profil}
                alt="driver"
                className="driver-icon mr-8"
                loading="lazy"
              />
            ) : (
              <img
                src={Avatar}
                alt="driver"
                className="driver-icon mr-8"
                loading="lazy"
              />
            )}
            <div className="driver-name-status">
            <p className="legend bleuM500 mb-0">
              {props?.conducteur?.prenom && 
                `${props.conducteur.prenom.charAt(0).toUpperCase()}${props.conducteur.prenom.slice(1).toLowerCase()}`
              }
              {props?.conducteur?.nom && ` ${props.conducteur.nom.charAt(0).toUpperCase()}.`}
            </p>
              {props?.conducteur?.description !== "" &&
                props?.conducteur?.documents?.length > 3 &&
                props?.conducteur?.vehicule && (
                  <div className="badge-confirme ">Conducteur confirmé</div>
                )}
            </div>
          </div>
          <div className="message  text-end">
            {props?.conducteur?.api !== 0 &&
            props?.conducteur?.id != localStorage.getItem("userData") ? (
              <button
                className="cta"
                onClick={() => {
                  checkdiscussion(props?.conducteur?.id);
                }}
              >
                <img src={Message} alt="message" className="" loading="lazy" />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        {props?.conducteur?.api === 0 && (
          <div className="isFlexible mt-16">
            Ce passager a utilisé la hotline Atchoum pour sa demande de trajet.
            Par conséquent, vous ne pouvez pas le contacter via la messagerie de
            la plateforme.
          </div>
        )}
        {props?.type === "passager" && (
          //&& props?.withBtn
          <div className="badge-confirme text-start mt-16">
            {" "}
            <img src={Users} alt="multiple users" className="mr-8" />
            Demande pour {props?.reservation?.nbrplace} personne(s)
          </div>
        )}
        {/* {props?.reservation?.dateReservation && props?.type === "passager" && (
          <div className="badge-confirme text-start mt-16">
            Réservation pour{" "}
            {new Date(props?.reservation?.dateReservation).toLocaleDateString(
              "fr-FR"
            )}
          </div>
        )} */}
        <p className="current-text mt-16 pb-16">
          {props?.conducteur?.description}
        </p>
        {props?.conducteur?.vehicule?.accepte_pmr === 1 && (
          <p className="current-text bleu400 mt-8 d-flex  pb-16">
            <img
              src={CheckFill}
              alt="check icon"
              className="mr-8"
              loading="lazy"
            />
            Mon véhicule est équipé pour accueillir une personne à mobilité
            réduite (PMR)
          </p>
        )}
        {props?.conducteur?.vehicule?.accepte_animaux === 1 && (
          <p className="current-text bleu400 mt-8 d-flex  pb-16">
            <img
              src={CheckFill}
              alt="check icon"
              className="mr-8"
              loading="lazy"
            />
            J’accepte les animaux de compagnie à bord
          </p>
        )}
        <div className="border-b"></div>
        <p className="exergue bleuM500 mb-0 mt-16">Numéro de téléphone</p>
        {(props.withBtn &&
          props?.type === "passager" &&
          props?.reservation?.status === "accepted") ||
        props?.reservation?.status === "accepted" ? (
          <p className="current-text bleuM500 mt-8 mb-0 border-b pb-16">
            {formatPhoneNumber(props?.conducteur?.telephone)}
          </p>
        ) : (
          <p className="current-text bleuM500 mt-8 mb-0 border-b pb-16">
            Son numéro de téléphone sera visible une fois la réservation
            acceptée
          </p>
        )}
        <p className="current-text bleuM500 mt-16">
          {props?.conducteur?.nombreTrajets || 0} trajet(s) effectué(s)
        </p>
        <p className="current-text bleuM500 mt-16 border-b pb-16">
          {(() => {
            if (yearsSinceCreation < 1) {
              if (monthsSinceCreation < 1) {
                return "Nouveau membre";
              } else if (monthsSinceCreation === 1) {
                return "Membre depuis 1 mois";
              } else {
                return `Membre depuis ${monthsSinceCreation} mois`;
              }
            } else if (yearsSinceCreation === 1) {
              return "Membre depuis 1 an";
            } else {
              return `Membre depuis ${yearsSinceCreation} ans`;
            }
          })()}
        </p>

        {/* <p className="current-text bleuM500 mt-16 border-b pb-16">
          Membre depuis {yearsSinceCreation} ans
        </p> */}
        {props?.type === "passager" && props?.withBtn && (
          <div className="btn-block d-flex align-items-center justify-content-between mt-8">
            <button
              className="btn-secondary-s p-14 "
              onClick={() =>
                handleUpdateStatus("refused")
              }
              disabled={loading}
            >
              Refuser
            </button>
            <button
              className="btn-primary-s p-14"
              disabled={loading}
              onClick={() =>
                handleUpdateStatus("accepted")
              }
            >
              {loading}
              {loading ? "Chargement..." : "Accepter le passager"} 
            </button>
          </div>
        )}
        <div className="mt-16">
          {props?.conducteur?.id != localStorage.getItem("userData") && (
            <button
              className="cta text-underline"
              disabled={
                props?.conducteur?.id == localStorage.getItem("userData")
              }
              onClick={() => {
                props?.setOpen(!props?.open);
              }}
            >
              Signaler ce membre
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default Conducteur;
