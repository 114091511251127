import "../assets/styles/covoiturage.scss";
import Avatar from "../assets/images/conducteur.svg";
import ArrowUp from "../assets/images/Arrow-right.svg";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalConnexion from "./modalConnexion";
const BlocTrajet = ({
  data,
  el,
  type,
  path,
  from,
  inputs_data,
  status,
  id,
  connected,
}) => {
  const navigate = useNavigate();
  const [modalLogin, setModalLogin] = useState(false);
  const [time, setTime] = useState({
    aller: { dept: "", arr: "" },
    retour: { dept: "", arr: "" },
  });
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedDateRetour, setFormattedDateRetour] = useState("");
  const communes = JSON.parse(data?.communes);
  const reservation = data?.trajet_reservation?.filter(
    (pa) => pa?.user_id == localStorage.getItem("userData")
  );
  // console.log({data})

    // Filter accepted reservations and sum their nbPlace values
    const acceptedPlaces = data?.trajet_reservation
      .filter(reservation => reservation.status === 'accepted')
      .reduce((total, reservation) => total + reservation.nbrplace, 0);

  const formatDate = (dateString, isReturn = false) => {
    if (!dateString) return; // Handle empty or undefined dateString

    const [datePart, timePart] = dateString.split(" ");
    if (!datePart || !timePart) return; // Handle invalid dateString format

    const [year, month, day] = datePart.split("-").map(Number);
    const date = new Date(year, month - 1, day);

    const daysOfWeek = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const monthNames = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];

    const dayName = daysOfWeek[date.getDay()];
    const monthName = monthNames[month - 1];

    const [hours, minutes] = timePart.split(":").map(Number);

    const durationHours = parseInt(data?.duration?.split("h")[0]) || 0;
    const durationMinutes = parseInt(data?.duration?.split("h")[1]) || 0;

    // Calculate arrival time
    const totalMinutes = minutes + durationMinutes;
    const additionalHours = Math.floor(totalMinutes / 60);
    const finalMinutes = totalMinutes % 60;

    const totalHours = hours + durationHours + additionalHours;
    const finalHours = totalHours % 24; // Wrap around if hours exceed 24

    const deptTime = `${hours.toString().padStart(2, "0")}h${minutes
      .toString()
      .padStart(2, "0")}`;
    const arrTime = `${finalHours.toString().padStart(2, "0")}h${finalMinutes
      .toString()
      .padStart(2, "0")}`;

    if (isReturn) {
      setTime((prev) => ({
        ...prev,
        retour: { dept: deptTime, arr: arrTime },
      }));
      setFormattedDateRetour(
        `${dayName} ${day.toString().padStart(2, "0")} ${monthName}`
      );
    } else {
      setTime((prev) => ({
        ...prev,
        aller: { dept: deptTime, arr: arrTime },
      }));
      setFormattedDate(
        `${dayName} ${day.toString().padStart(2, "0")} ${monthName}`
      );
    }
  };

  // if user not connected so open modal login
  const clickCardTrajet = () => {
    // console.log({connected})
    const url = `${path}${data?.id}`;
    connected
      ? navigate(`${url}`, { state: { inputs_data: inputs_data } })
      : setModalLogin(true);
   
  };

  useEffect(() => {
    // if (data?.Trajetregulier && !inputs_data) {
     
    //   setFormattedDate(
    //     reservation?.length > 0 && reservation[0]?.dateReservation
    //       ? reservation[0]?.dateReservation
    //       : "Trajet régulier"
    //   );
    //   setTime({
    //     aller: { dept: "", arr: "" },
    //     retour: { dept: "", arr: "" },
    //   });
    // }

    // if (data?.Trajetregulier) {
    //   setTime({
    //     aller: { dept: data?.horaires[0]?.timeTrajet, arr: data?.horaires[0]?.timeTrajet },
    //     retour: { dept: "", arr: "" },
    //   });
    // }
    if (data?.dateDepart ) formatDate(data?.dateDepart);

    if (data?.dateRetour)
      formatDate(data?.dateRetour, true);
    if (data?.isFlexibleDest && !data?.Trajetregulier)
      setFormattedDateRetour("Flexible");
    if (data?.isFlexibleDep && !data?.Trajetregulier)
      setFormattedDate("Flexible");
  }, [data]);
  return (
    <>
      {modalLogin && (
        <ModalConnexion open={modalLogin} setOpen={setModalLogin} />
      )}
      <div  key={id}>
        <div className="bloc-trajet mt-8">
          <div onClick={clickCardTrajet} className="no-decoration">
            {from && from === "myTargets" && (
              <div className="d-flex align-items-center justify-content-between">
                <p className="subtitle bleuM500">
                  {data?.isFlexibleDest || data?.dateRetour
                    ? `${communes?.dept} / ${communes?.dest}`
                    : formattedDate}
                </p>
                <p className="tag-trajet">
                  {data?.typeTrajet === 0 ? "Trajet solidaire" : "Covoiturage"}
                </p>
              </div>
            )}

            {inputs_data && (
              <div className="d-flex align-items-center justify-content-between">
                <p className="subtitle bleuM500">
                  {data?.dateDepart ? (
                    formattedDate
                  ) : data?.Trajetregulier ? (
                    "Trajet régulier"
                  ) : (
                    <>
                      Trajet régulier{" "}
                      <span className="legend bleuM500"> (Voir horaires) </span>
                    </>
                  )}
                </p>
              </div>
            )}
            {data?.dateRetour || data?.isFlexibleDest ? (
              <>
                <div className="price d-flex align-items-center justify-content-between mt-16">
                  <p className=" bleuM500 ">Aller - {formattedDate}</p>
                  <p className="bleuM500 mb-0 text-end">
                    {parseFloat(data?.prixTrajet)?.toFixed(2) || 0} €
                  </p>
                </div>

                <div className="trip-details  pb-16 mt-16">
                  <div className="trip-time">
                    <span className=" exergue bleuM500 mb-20">
                      {data?.dateDepart && time?.aller?.dept}
                    </span>
                    <span className=" legend bleu400 mb-20">
                      {data?.duration}
                    </span>
                    <span className=" exergue bleuM500">
                      {data?.dateDepart && time?.aller?.arr}
                    </span>
                  </div>

                  <div className="ml-8 trip-address align-items-start">
                    <p className="legend mb-0 bleuM500">{communes?.dept}</p>

                    <p className="legend mb-0 bleuM500">{communes?.dest}</p>
                  </div>
                </div>
                <p className="subtitle bleuM500 ">
                  Retour - {formattedDateRetour}
                </p>
                <div className="trip-details border-b pb-16 mt-16">
                  <div className="trip-time">
                    <span className=" exergue bleuM500 mb-20">
                      {!data?.isFlexibleDest &&
                        data?.dateDepart &&
                        time.retour.dept}
                    </span>
                    <span className=" legend bleu400 mb-20">
                      {data?.duration}
                    </span>
                    <span className=" exergue bleuM500">
                      {!data?.isFlexibleDest &&
                        data?.dateDepart &&
                        time.retour.arr}
                    </span>
                  </div>

                  <div className="ml-8 trip-address align-items-start">
                    <p className="legend mb-0 bleuM500">{communes?.dest}</p>

                    <p className="legend mb-0 bleuM500">{communes?.dept}</p>
                  </div>
                  <div className="price text-end"></div>
                </div>
              </>
            ) : (
              <div className="trip-details border-b pb-16 mt-16">
                <div className="trip-time">
                  <span className=" exergue bleuM500 mb-20">
                    {time.aller.dept}
                  </span>
                  <span className=" legend bleu400 mb-20">
                    {data?.duration}
                  </span>
                  <span className=" exergue bleuM500">{time.aller.arr}</span>
                </div>
                {/* <img src={TripRight} alt="trip icon" className="driver-icon mr-8" /> */}

                <div className="ml-8 trip-address align-items-start">
                  <p className="legend mb-0 bleuM500">{communes?.dept}</p>
                 
                  <p className="legend mb-0 bleuM500">{communes?.dest}</p>
                </div>
                <div className="price text-end">
                  <p className="bleuM500 mb-0 text-end">
                    {parseFloat(data?.prixTrajet)?.toFixed(2) || 0} €
                  </p>
                </div>
              </div>
            )}

            {from === "myTargets" ? (
              <div className=" mt-16">
                {(status === "onHold" || status === "tous") &&
                el?.role === "conducteur" &&
                data?.trajet_reservation?.filter((el) => el.status === "onHold")
                  .length !== 0 &&
                data?.typeTrajet === 1 ? (
                  <>
                    {" "}
                    <p className="exergue">
                      {
                        data?.trajet_reservation?.filter(
                          (el) => el.status === "onHold"
                        ).length
                      }{" "}
                      demande(s)
                      <span className="ml-8 legend bleu400">
                        {el?.nbr_place - el?.reserved_places} place(s) disponible
                      </span>
                    </p>
                    <div className="success mt-8">
                      Acceptez ou refusez les passagers{" "}
                      <img src={ArrowUp} alt="arrow up icon" />
                    </div>
                  </>
                ) : (status === "onHold" || status === "tous") &&
                  el?.role === "conducteur" &&
                  data?.trajet_reservation &&
                  data?.trajet_reservation?.filter(
                    (el) => el.status === "onHold"
                  ).length !== 0 &&
                  data?.typeTrajet === 0 ? (
                  <div className="success mt-8">
                    Acceptez le passager{" "}
                    <img src={ArrowUp} alt="arrow up icon" />
                  </div>
                ) : (status === "onHold" || status === "tous") &&
                  el?.role === "conducteur" &&
                  (data?.trajet_reservation?.filter(
                    (el) => el.status === "onHold"
                  ).length === 0 ||
                    !data?.trajet_reservation) ? (
                  <>
                    <p className="exergue">
                      Aucune demande
                      <span className="ml-8 legend bleu400">
                        {el?.nbr_place - el?.reserved_places} place(s)
                        disponible
                      </span>
                    </p>
                  </>
                ) : status === "passes" && el?.role === "conducteur" ? (
                  <></>
                ) : status === "avenir" && el?.role === "conducteur" ? (
                  <div className="badge-confirme text-start">
                    Trajet complet - {el?.reserved_places} passager(s)
                  </div>
                ) : (
                  <div className="driver-details mt-16">
                    <div className="driver-info">
                      {data?.conducteurtrajet?.photo_profil ? (
                        <img
                          src={data?.conducteurtrajet?.photo_profil}
                          alt="driver"
                          className="driver-icon mr-8"
                          loading="lazy"
                        />
                      ) : (
                        <img
                          src={Avatar}
                          alt="driver"
                          className="driver-icon mr-8"
                          loading="lazy"
                        />
                      )}
                      <div className="driver-name-status">
                        <p className="legend bleuM500 mb-0">
                          {type === "demand"
                            ? `${reservation?.reservation_user?.nom} ${reservation?.reservation_user?.prenom}`
                            : `${data?.conducteurtrajet?.nom} ${data?.conducteurtrajet?.prenom}`}
                        </p>
                        {data?.conducteurtrajet?.description !== "" &&
                          data?.conducteurtrajet?.documents?.length > 3 &&
                          data?.conducteurtrajet?.vehicule &&
                          type === "trajet" && (
                            <div className="badge-confirme ">
                              Conducteur confirmé
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="seats text-end">
                      <p className="legend bleu400 text-end">
                        {reservation && reservation[0]?.status === "onHold"
                          ? "Demande envoyée"
                          : reservation && reservation[0]?.status === "accepted"
                          ? ""
                          : ""}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="driver-details mt-16">
                <div className="driver-info">
                  {data?.conducteurtrajet?.photo_profil ? (
                    <img
                      src={data?.conducteurtrajet?.photo_profil}
                      alt="driver"
                      className="driver-icon mr-8"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      src={Avatar}
                      alt="driver"
                      className="driver-icon mr-8"
                      loading="lazy"
                    />
                  )}
                  <div className="driver-name-status">
                    <p className="legend bleuM500 mb-0">
                      {`${data?.conducteurtrajet?.nom} ${data?.conducteurtrajet?.prenom}`}
                    </p>
                    {data?.conducteurtrajet?.description !== "" &&
                      data?.conducteurtrajet?.documents?.length > 3 &&
                      data?.conducteurtrajet?.vehicule &&
                      type === "trajet" && (
                        <div className="badge-confirme ">
                          Conducteur confirmé
                        </div>
                      )}
                  </div>
                </div>
                <div className="seats">
                  <p className="legend bleuM500">{data?.nbrPlace - acceptedPlaces} place(s)</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BlocTrajet;
